<template>
  <div class="bg-container">
    <img class="pattern" src="./assets/pattern.svg" />
    <div class="square">
      <img class="logo" alt="Localhost logo" src="./assets/logo.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.bg-container {
  background-color: #74479c;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  box-sizing: border-box;
}
.square {
  position: absolute;
  background-color: #fff;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 400px;
  width: 400px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.logo {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 75px;
}
.pattern {
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
}
@media all and (max-width: 768px) {
  .bg-container {
    box-sizing: border-box;
  }
  .square {
    /* margin: -100px 0 0 -150px; */
    width: 280px;
    border-radius: 10px;
    height: 280px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .logo {
    position: absolute;
    height: 50px;
  }
  .pattern {
    position: relative;
    display: flex;
    height: 40%;
    width: 100%;
    opacity: 0.5;
    top: 50%;
  }
}
</style>
